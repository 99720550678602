
import s from './App.module.scss';
import DumbBitchImage from './assets/dumbBitchJuice.png';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

const Home = () => {
  const query = new URLSearchParams(useLocation().search);
  const reason = query.get('reason');

  return (
    <div className={s.pageContainer}>
        <img src={DumbBitchImage} alt="dog drinkin some dumb bitch juice"/>
        <div className={s.textContainer}>
          <h1>{`Yes`}</h1>
          <h3>{`You are a dumb bitch${reason ? `, because you ${reason}.` : ', so drink this juice.'}`}</h3>
          {reason && <h3>So drink this juice.</h3>}
        </div>
        
    </div>
  );
};

const App = () => {
  return(
    <Router>
    <Switch>
      <Route to="/">
        <Home />
      </Route>
    </Switch>
    </Router>
  )
}

export default App;
